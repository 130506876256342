checkScrollPos();

window.onscroll = function(ev) {
    checkScrollPos();
    scrollingDirection();        
};

function scrollingDirection() {
    if (this.oldScroll > this.scrollY) {
        document.body.classList.add("scrolling-up");
        document.body.classList.remove("scrolling-down");
    } else {
        document.body.classList.remove("scrolling-up");
        document.body.classList.add("scrolling-down");
    }
    this.oldScroll = this.scrollY;
}

function checkScrollPos() {
    const header = document.querySelector('[is=flynt-layout-home-header],[is=flynt-layout-post-header]');
    const headerPos = header ? header.getBoundingClientRect() : null;

    // Window is within 10px of the top
    if (window.scrollY <=  10) {
        document.body.classList.add("at-top");
    } else {
        document.body.classList.remove("at-top");
    }

    // Window has scrolled passed the header
    if (headerPos){
        if (headerPos.bottom < 0) {
            document.body.classList.add("passed-header");
        } else {
            document.body.classList.remove("passed-header");
        }
    }
    

    // Window is within 10px of the bottom
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - 10) {
        document.body.classList.add("at-bottom");
    } else {
        document.body.classList.remove("at-bottom");
    }
}