let movings = document.querySelectorAll('.scrollout');
let vh = (window.innerHeight / 4) *3 ;

document.addEventListener('scroll', function(e) {  
  
  movings.forEach((el) => {
    
    let rect = el.getBoundingClientRect();
    let rectBot = rect.y + rect.height;
    let percent = ((rectBot / vh * 33) - 33);
    let min = 0;
    let max = -50;

    if (el.classList.contains('image-right')) {
        max = max * -1;
        percent = percent * -1
    }

    
    if (rectBot <= vh && rectBot >= 0) {
      el.style.transform = 'translateX('+ percent +'%)';
    } else if(rectBot > vh ) {
      el.style.transform = 'translateX(' + min + '%)';
    } else if (rectBot < 0) {
      el.style.transform = 'translateX(' + max + '%)';
    }
    
  });
  
});