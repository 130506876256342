var Flickity = require("flickity");

document.addEventListener("DOMContentLoaded", (e) => {


  // Init home slider flickity
  var logoSlider = document.querySelector(
    '.logo-carousel'
  );
  if (logoSlider){
    var flkty = new Flickity(logoSlider, {
      // options
      cellAlign: "left",
      contain: true,
      autoPlay: logoSlider.dataset.marquee != null ? null : 3000,
      prevNextButtons: false,
      // imagesLoaded: true,
      wrapAround: true,
      selectedAttraction: 0.01,
      friction: 0.25,
      pageDots: false,
      marquee: logoSlider.dataset.marquee != null
    });   
  }

  if (flkty && flkty.options.marquee){

// Set initial position to be 0
flkty.x = 0;

// Start the marquee animation
play();

// Main function that 'plays' the marquee.
function play() {
  // Set the decrement of position x
  flkty.x -= 1.5;

  // Settle position into the slider
  flkty.settle(flkty.x);

  // Set the requestId to the local variable
  requestId = window.requestAnimationFrame(play);
}

// Main function to cancel the animation.
function pause() {
  if(requestId) {
    // Cancel the animation
    window.cancelAnimationFrame(requestId)

    // Reset the requestId for the next animation.
    requestId = undefined;
  }
}

// Pause on hover/focus
$('.js-main-slider').on('mouseenter focusin', e => {
  pause();
})

// Unpause on mouse out / defocus
$('.js-main-slider').on('mouseleave', e => {
  play();
})

  }

});