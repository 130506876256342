document.addEventListener("click", function (e) {
   // Nav Toggle
  if (e.target && e.target.closest(".nav-toggle")) {
    console.log("in");
    toggleNav();
  }

  // Dropdowns
  if (e.target && e.target.matches('.sub-menu-button')) {    
    var toggle = e.target;
    var target = toggle.nextElementSibling;
    dropdownToggle(toggle, target, 0);    
  }
});

let openNav = () => {
  console.log("openn");  
  document.body.classList.add('pause-overflow');
  document.querySelector('nav .main-links').classList.add('open-nav');
  document.querySelector('nav .nav-toggle').classList.add('open');
  console.log(document.querySelector('nav .nav-toggle'));

}
let closeNav = () => {  
  console.log("close");
  document.body.classList.remove('pause-overflow');
  document.querySelector('nav .main-links').classList.remove('open-nav');
  document.querySelector('nav .nav-toggle').classList.remove('open');

}
let toggleNav = () => {
  if (document.querySelector('nav .main-links.open-nav')){
    closeNav();
  }
  else {
    openNav();
  }
}


let dropdowns = document.querySelectorAll('.menu-item-has-children');

dropdowns.forEach((el) => {
  el.addEventListener("mouseover", function (e) {
    if (window.matchMedia('hover:hover')) {
      dropdowns.forEach((el) => {
        let sub = el.querySelector('.sub-menu');
        sub.classList.remove('open');
        sub.style.maxHeight = 0 + "px";
      })
      let sub = el.querySelector('.sub-menu');
      var height = sub.scrollHeight; 
      sub.classList.add('open');
      sub.style.maxHeight = height + "px";
    }
    });    
});

document.querySelector('nav').addEventListener("mouseleave", function (e) {

  if (window.matchMedia('hover:hover')) {
    dropdowns.forEach((el) => {
      let sub = el.querySelector('.sub-menu');
      sub.classList.remove('open');
      sub.style.maxHeight = 0 + "px";
    })
  } 
 
});

function dropdownToggle(toggle, target, min) {
  var content = target.children;
  var height = target.scrollHeight; 
  if (toggle.classList.contains('open')) {
    toggle.classList.remove('open');
    target.classList.remove('open');
    target.style.maxHeight = min + "px";
  } else {
    toggle.classList.add('open');
    target.classList.add('open');
    target.style.maxHeight = height + "px";
  }
}