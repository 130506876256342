var Flickity = require("flickity");

document.addEventListener("DOMContentLoaded", (e) => {


  // Init home slider flickity
  var caseSlider = document.querySelector(
    '.case-slider'
  );
  if (caseSlider){
    var flktyCase = new Flickity(caseSlider, {
      // options
      cellAlign: "left",      
      contain: true,
      prevNextButtons: true,
      // imagesLoaded: true,
      wrapAround: false,
      selectedAttraction: 0.01,
      friction: 0.25,
      pageDots: true,
      fade: true,
    });   
  }

});