const mouseBgs = document.querySelectorAll(".mouse-bg");

document.addEventListener("mousemove", (e) => {
    if (e.sourceCapabilities.firesTouchEvents == false){
        mouseBgs.forEach((el)=>{
            let bounds = el.getBoundingClientRect();
            let xMod =  ((e.clientX - bounds.left) - el.offsetLeft) / 3 + "px";
            let yMod =  ((e.clientY - bounds.top) - el.offsetTop) / 5 + "px";
            el.style.transform = 'translate(calc(-70% + '+ xMod +'), calc(-50%))';  
          }) 
    }  
});