
var Flickity = require("flickity");

document.addEventListener("DOMContentLoaded", (e) => {

  // Init home slider flickity
  var segmentSlider = document.querySelector(
    '[is="flynt-block-timeline"] .segments'
  );
  if (segmentSlider){
    var flkty = new Flickity(segmentSlider, {
      // options
      cellAlign: "left",
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true,
      // setGallerySize: false
    });
  }


});