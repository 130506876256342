ScrollReveal().reveal('.reveal', {
    delay: 100,
    distance: '50px',
    interval: 50,    
});

ScrollReveal().reveal('[is=flynt-layout-home-header] .content, [is=flynt-layout-home-header] .ctas', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});

ScrollReveal().reveal('[is=flynt-block-video-split] .media, [is=flynt-block-video-split] .content', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});

ScrollReveal().reveal('[is=flynt-block-cta] .content p', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});

ScrollReveal().reveal('[is=flynt-block-icon-row] h2, [is=flynt-block-icon-row] .entries article', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});

ScrollReveal().reveal('[is=flynt-block-pullquote] h2', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});

ScrollReveal().reveal('[is=flynt-block-case-study-meta] .content *', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});

ScrollReveal().reveal('[is=flynt-block-case-study-meta] .quote *', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});

ScrollReveal().reveal('[is=flynt-block-logo-carousel] h2, [is=flynt-block-logo-carousel] img', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});

ScrollReveal().reveal('[is=flynt-block-latest-posts] .intro, [is=flynt-block-latest-posts] .post', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});

ScrollReveal().reveal('[is=flynt-layout-page-header] h1, [is=flynt-layout-page-header] .desc', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});
ScrollReveal().reveal('[is=flynt-block-split-content] .image, [is=flynt-block-split-content] .content', {
    delay: 100,
    distance: '50px', 
    interval: 50,  
});
ScrollReveal().reveal('[is=flynt-block-people] .title, [is=flynt-block-people] .desc, [is=flynt-block-people] .person', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});
ScrollReveal().reveal('.posts .case-study-snippit div', {
    delay: 100,
    distance: '50px',
    interval: 50,  
});
/*
  .reveal, [is=flynt-layout-home-header] .content, [is=flynt-layout-home-header] .ctas, [is=flynt-block-video-split] div, [is=flynt-block-cta] .content p, [is=flynt-block-icon-row] h2, [is=flynt-block-icon-row] .entries article,
  [is=flynt-block-icon-row] h2, [is=flynt-block-icon-row] .entries article, [is=flynt-block-pullquote] h2, [is=flynt-block-case-study-meta] .content *, [is=flynt-block-case-study-meta] .quote *, [is=flynt-block-logo-carousel] h2, [is=flynt-block-logo-carousel] img,
  [is=flynt-block-latest-posts] .intro, [is=flynt-block-latest-posts] .post, [is=flynt-layout-page-header] h1, [is=flynt-layout-page-header] .desc, [is=flynt-block-split-content] .image, [is=flynt-block-split-content] .content,
  [is=flynt-block-people] .title, [is=flynt-block-people] .desc, [is=flynt-block-people] .person, .posts .case-study-snippit div
*/  