if (document.querySelector('[is="flynt-block-job-listing"]')) {
  console.log('job listing block')
  document.addEventListener('DOMContentLoaded', function () {
    const proxyUrl = '/entrust-test/wp-content/themes/ers/api-proxy.php'
    const allJobs = []
    let links = {}
    const currentSearchTerm = ''

    async function fetchAdDetails (jobId) {
      try {
        const response = await fetch(
          `${proxyUrl}?url=${encodeURIComponent(
            `https://api.jobadder.com/v2/jobboards/7146/ads?reference=${jobId}`
          )}`
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        return data.items.length > 0 ? data.items[0].adId : null
      } catch (error) {
        console.error('Error fetching ad details:', error)
        return null
      }
    }

    async function fetchJobDetails (jobId) {
      try {
        const response = await fetch(
          `${proxyUrl}?url=${encodeURIComponent(
            `https://api.jobadder.com/v2/jobs/${jobId}`
          )}`
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const jobData = await response.json()
        const adId = await fetchAdDetails(jobId)
        return { ...jobData, adId }
      } catch (error) {
        console.error('Error fetching job details:', error)
        return null
      }
    }

    function createSlug (title) {
      return title
        .toLowerCase()
        .replace(/[,&]/g, '') // Remove commas and ampersands
        .replace(/[ \-]+/g, '-') // Replace spaces and hyphens with a single hyphen
        .replace(/[^a-z0-9-]/g, '') // Remove any remaining non-alphanumeric characters except hyphens
    }

    function displayJobs (jobs) {
      const jobsContainer = document.getElementById('jobs-listing')
      const noDataContainer = document.getElementById('no-data')
      jobsContainer.innerHTML = ''
      if (jobs.length === 0) {
        noDataContainer.style.display = 'block'
        jobsContainer.style.display = 'none'
      } else {
        noDataContainer.style.display = 'none'
        jobsContainer.style.display = 'grid'
        jobsContainer.classList.remove('first-load')
        jobs.forEach((job) => {
          console.log(job)
          const slug = createSlug(job.jobTitle)
          const jobElement = document.createElement('div')
          jobElement.className = 'job'
          jobElement.innerHTML = `
                  <h3><a title="${job.jobTitle}" target="_blank" href="https://clientapps.jobadder.com/34831/entrust-resource-solutions/${
                    job.jobObj.adId
                  }/${slug}">${job.jobTitle}</a></h3>
                  
                  <p><strong>${getReadableSalaryString(job.jobObj.salary)}</strong></p>
                    <hr>
                    <div class="meta">
                    <span><i class="fa-regular fa-clock"></i>${
                        job.jobObj.workType.name ?? '-'
                      }</span>
                      <span><i class="fa-solid fa-location-dot"></i>${
                        job.location?.name ?? '-'
                      }</span>
                      <span><i class="fa-regular fa-address-book"></i>
                        ${job.owner?.firstName ?? '-'} ${job.owner?.lastName ?? '-'} (${job.owner?.email ?? '-'})
                      }</span>                      
                      <span><i class="fa-regular fa-clock"></i> ${new Date(
                        job.createdAt
                      ).toLocaleString()}</span>                      
                      <span><i class="fa-regular fa-lightbulb"></i> ${
                        job.status?.name ?? '-'
                      }</span>
                    </div>                    
                    <a title="${job.jobTitle}" href="https://clientapps.jobadder.com/34831/entrust-resource-solutions/${
                      job.jobObj.adId
                    }/${slug}" target="_blank">View Job Details ></a>
              `
          jobsContainer.appendChild(jobElement)
        })
      }
    }

    function showLoader () {
      document.getElementById('loader').style.display = 'flex'
      if (!document.querySelector('.first-load')) {
        const element = document.getElementById('job-search')

        // Ensure the element is in view
        const rect = element.getBoundingClientRect()
        const elementTop = rect.top + window.scrollY

        // Scroll to the element with an offset
        window.scrollTo({
          top: elementTop - 124, // Offset the position by 100px from the top of the viewport
          behavior: 'smooth' // Optional: adds a smooth scrolling animation
        })
      }
    }

    function hideLoader () {
      document.getElementById('loader').style.display = 'none'
    }

    function updatePaginationButtons () {
      document.getElementById('first-btn').disabled = !links.prev
      document.getElementById('prev-btn').disabled = !links.prev
      document.getElementById('next-btn').disabled = !links.next
      document.getElementById('last-btn').disabled = !links.last
    }

    // async function fetchJobs(url, searchTerm = '') {
    //     showLoader();
    //     const searchUrl = searchTerm ? `${url}&jobTitle=${encodeURIComponent(searchTerm)}` : url;
    //     try {
    //         const response = await fetch(`${proxyUrl}?url=${encodeURIComponent(searchUrl)}`);
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }
    //         const data = await response.json();
    //         if (data.items) {
    //             const jobDetailsPromises = data.items.map(job => fetchJobDetails(job.jobId));
    //             allJobs = await Promise.all(jobDetailsPromises);
    //             console.log('allJobs', allJobs);
    //             links = data.links || {};
    //             displayJobs(allJobs.filter(job => job !== null));
    //             updatePaginationButtons();
    //         } else {
    //             allJobs = [];
    //             links = {};
    //             displayJobs(allJobs);
    //             updatePaginationButtons();
    //         }
    //     } catch (error) {
    //         console.error('Error fetching jobs:', error);
    //     } finally {
    //         hideLoader();
    //     }
    // }
    async function fetchJobs (url, searchTerm = '') {
      showLoader()
      const searchUrl = searchTerm
        ? `${url}&jobTitle=${encodeURIComponent(searchTerm)}`
        : url
      try {
        const response = await fetch(
          `${proxyUrl}?url=${encodeURIComponent(searchUrl)}`
        )
        if (!response.ok) throw new Error('Network response was not ok')
        const data = await response.json()

        if (data.items) {
          // Process job data and resolve adId
          const jobDetailsPromises = data.items.map(async (job) => {
            // const adId = await fetchAdDetails(job.jobId) // Wait for adId to be resolved
            const jobObj = await fetchJobDetails(job.jobId) // Wait for adId to be resolved
            return { ...job, jobObj } // Return job data with resolved adId
          })

          // Wait for all job details to be processed
          const processedJobs = await Promise.all(jobDetailsPromises)

          console.log('processedJobs', processedJobs)
          displayJobs(processedJobs) // Use processed jobs directly
          links = data.links || {}
          console.log(links)
          updatePaginationButtons()
        } else {
          displayJobs([])
          links = {}
          updatePaginationButtons()
        }
      } catch (error) {
        console.error('Error fetching jobs:', error)
      } finally {
        hideLoader()
      }
    }

    document.getElementById('first-btn').addEventListener('click', () => {
      if (links.first) {
        fetchJobs(links.first, currentSearchTerm)
      }
    })

    document.getElementById('prev-btn').addEventListener('click', () => {
      if (links.prev) {
        fetchJobs(links.prev, currentSearchTerm)
      }
    })

    document.getElementById('next-btn').addEventListener('click', () => {
      if (links.next) {
        fetchJobs(links.next, currentSearchTerm)
      }
    })

    document.getElementById('last-btn').addEventListener('click', () => {
      if (links.last) {
        fetchJobs(links.last, currentSearchTerm)
      }
    })

    const searchInput = document.getElementById('job-search')
    let typingTimer // Timer identifier
    const typingDelay = 1000 // 1 second delay

    searchInput.addEventListener('input', (event) => {
      clearTimeout(typingTimer) // Clear the previous timer
      typingTimer = setTimeout(() => {
        const currentSearchTerm = event.target.value
        fetchJobs(
          'https://api.jobadder.com/v2/jobs?Limit=10&statusId=9347',
          currentSearchTerm
        )
      }, typingDelay)
    })

    // Initial fetch
    fetchJobs('https://api.jobadder.com/v2/jobs?Limit=10&statusId=9347')
  })
}

function truncateHTML (htmlString, wordLimit) {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html')
  const textNodes = doc.body.querySelectorAll('*')
  let wordCount = 0
  let truncated = ''

  for (const node of textNodes) {
    const text = node.innerHTML.trim()
    const words = text.split(/\s+/)

    if (wordCount + words.length > wordLimit) {
      const wordsToKeep = wordLimit - wordCount
      truncated += words.slice(0, wordsToKeep).join(' ') + '...'
      break
    } else {
      truncated += node.outerHTML
      wordCount += words.length
    }
  }

  return truncated
}

function getReadableSalaryString (salaryObject) {
  const { ratePer, rateLow, rateHigh, currency } = salaryObject

  // Convert currency code to symbol (assuming it's GBP, you can expand this)
  let currencySymbol
  switch (currency) {
    case 'GBP':
      currencySymbol = '£'
      break
    case 'USD':
      currencySymbol = '$'
      break
    case 'EUR':
      currencySymbol = '€'
      break
      // Add more currencies as needed
    default:
      currencySymbol = currency
  }

  // Create the salary string
  let salaryString = `${currencySymbol}${rateLow.toLocaleString()}`

  if (rateHigh && rateHigh !== rateLow) {
    salaryString += ` - ${currencySymbol}${rateHigh.toLocaleString()}`
  }

  salaryString += ` per ${ratePer}`

  return salaryString
}
