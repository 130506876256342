/// header IDs
var headers = document.querySelectorAll('h1,h2,h3,h4,h5,h6');
headers.forEach(function(el){
    // console.log(el.textContent);
    var hAnchor = document.createElement("span");
    hAnchor.id = slugify(el.textContent);
    hAnchor.classList = 'h-anchor';
    hAnchor.setAttribute('aria-hidden', 'true');
    el.before(hAnchor);
});

function slugify (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}